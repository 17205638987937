import { useEffect, useState } from "react";
import { Layout } from "antd";
import { Grid } from "grommet";

import useMediaScreen from "utils/hooks/useMediaScreen";
import NewsCard from "./NewsCard";
import TopNewsCard from "./TopNewsCard";

import { fetchData } from "api/news.api";
import SuspenseFallback from "@components/core/modules/SuspenseFallback";
import { LazyLoadComponent } from "react-lazy-load-image-component";

const ITEM_PER_ROW_BY_SIZE = {
  small: 1,
  medium: 1,
  large: 2,
};
const GAP_BETWEEN_ITEMS = {
  small: 0,
  medium: {
    row: "small",
    column: "large",
  },
  large: {
    row: "medium",
    column: "large",
  },
};

const formatUrl = (articleId, title) => {
  return `/article/${title
    .toLowerCase()
    .replaceAll(" ", "-")
    .replaceAll("'", "-")
    .replaceAll("--", "-")
    .replaceAll(/[.,/#!?$%^&*;:{}=\-_`~()]/g, "-")
    .replaceAll("---", "-")}
/${articleId}`;
};

const News = () => {
  const [topArticle, setTopArticle] = useState(null);
  const [articles, setArticles] = useState();
  const [loading, setLoading] = useState(true);
  const { isLarge, isMedium } = useMediaScreen();

  let size = "small";
  let responsivePadding = "0px 25px 20px 25px";

  if (isMedium) size = "medium";
  if (isLarge) size = "large";

  if (size === "medium") responsivePadding = "0px 35px 0px 35px";
  if (size === "large") responsivePadding = "0px 25px 0px 25px";

  useEffect(() => {
    const getArticles = async () => {
      const res = await fetchData();
      setTopArticle(res.shift());
      setArticles(res);
      setLoading(false);
    };

    getArticles();
  }, []);

  if (loading) {
    return <SuspenseFallback />;
  }

  return (
    <Layout style={styles.container}>
      <Layout.Content
        style={{
          ...styles.content,
        }}
      >
        {topArticle && (
          <TopNewsCard
            url={formatUrl(topArticle.id, topArticle.title)}
            size={size}
            articleId={topArticle.id}
            title={topArticle.shortTitle || topArticle.title}
            coverImage={
              topArticle.coverImageResized
                ? topArticle.coverImageResized["large"]
                : topArticle.coverImage
            }
            description={topArticle.shortContent}
            context={`${topArticle.sport} ${
              topArticle.articleContext ? `, ${topArticle.articleContext}` : ""
            }`}
          />
        )}

        <LazyLoadComponent>
          <Grid
            style={{
              padding: responsivePadding,
            }}
            columns={{ count: ITEM_PER_ROW_BY_SIZE[size], size: "auto" }}
            gap={GAP_BETWEEN_ITEMS[size]}
          >
            {articles &&
              articles.map((article) => (
                <NewsCard
                  key={article.id}
                  url={formatUrl(article.id, article.title)}
                  articleId={article.id}
                  size={size}
                  title={article.shortTitle || article.title}
                  coverImage={
                    article.coverImageResized
                      ? article.coverImageResized["medium"]
                      : article.coverImage
                  }
                  description={article.shortContent}
                  context={`${article.sport} ${
                    article.articleContext ? `, ${article.articleContext}` : ""
                  }`}
                />
              ))}
          </Grid>
        </LazyLoadComponent>
      </Layout.Content>
    </Layout>
  );
};

const styles = {
  container: {
    width: "100%",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    background: "#f8f9fa",
  },
  content: {
    margin: "auto",
    width: "100%",
    height: "auto",
    maxWidth: "1000px",
    background: "#f8f9fa",
  },
};

export default News;
